import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import useSiteMetadata from '../components/SiteMetadata'

import Layout from '../components/Layout'
import PageBanner from '../components/PageBanner/PageBanner'

const VideoPage = ({ data }) => {
  const { title } = useSiteMetadata()
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <Helmet>
        <title>{`${frontmatter.title} | ${title}`}</title>
      </Helmet>
      <PageBanner title={ frontmatter.title } />

      <section>

        <div className="container pb-48">
          {frontmatter.videos.map(({ video: iframe }, index) => {
            return (
              <div className="embed-responsive aspect-ratio-16/9 mb-88">
                <div className="embed-responsive-item" dangerouslySetInnerHTML={{ __html: iframe }} />
              </div>
            )
          })}

        </div>

      </section>

    </Layout>
  )
}

VideoPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default VideoPage

export const pageQuery = graphql`
  query VideoPage {
    markdownRemark(frontmatter: { templateKey: { eq: "video-page" } }) {
      frontmatter {
        title
        videos {
          video
        }
      }
    }
  }
`
